import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section } from 'components/atoms';

const Spacer = ({ data, className }) => {
  const { size } = data;

  return (
    <Section className={cn('spacer', `spacer_${size}`, [className])}></Section>
  );
};

Spacer.defaultProps = {
  className: '',
};

Spacer.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Spacer;
